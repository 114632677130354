import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import mStripes from "../images/m-stripes.png";

import MiddleNav from './middlenav';
import settings from '../../settings';
import {withWindowSize} from "../hocs/withWindowSize";
import PropTypes from "prop-types";

const logo = `${settings.IMAGES_BASE_URL}/images/logos/bmw.svg`;
const bmwPerformanceLogoNew = `${settings.IMAGES_BASE_URL}/images/logos/BMW-pdc-logo-1.svg`;
const today = new Date();
const showGiftCardBar = 
  today >= new Date(2023,10,24) && 
  today < new Date(2024,0,1);
class Nav extends React.Component {
  state = {
    menuIsOpen: true,
    menuToggle: false,
    isTop: true,
    className: 'dropdown-link'
  };

  handleMenuToggle = () => {
    this.setState((prev) => {
      return {
        menuIsOpen: !prev.menuIsOpen,
        menuToggle: !prev.menuToggle
      };
    });
  };


  componentDidMount() {
    const url = typeof window !== 'undefined' ? window.location.href : '';

    if (url.indexOf('/teenschool') > -1 || url.indexOf('/driverschool') > -1) {
      this.setState({
        className: 'dropdown-link active'
      });
    }
  }

  render() {
    const breakpoint = this.props.site === 'BMW' ? 1376 : 1200
    const isMobile = this.props.width < breakpoint;

    return (
      <header>
        {/* <PopupModal leftLogo={darklogo} rightLogo={bmwlogo} /> */}
        <div className="mobile__nav">
          <div className="container">
            <Link to="/" className="mobile-logo" title="home" aria-label="back to home">
              <img src={logo} alt="Logo" aria-label="BMW logo"/>
            </Link>
            <div
              className={`hamburger ${this.state.menuIsOpen ? ' ' : 'is-open'}`}
              onClick={this.handleMenuToggle}
              onKeyDown={this.handleMenuToggle}
              role="button"
              tabIndex={0}
              aria-label="hamburger"
            >
              <div className="hamburger__toggle" />
            </div>
          </div>
        </div>
        <nav className={`nav ${this.state.menuToggle ? ' ' : 'hide-nav'}`}>
          <ul className="navbar">
          <li className="width-full">
           <MiddleNav isOpen={this.state.menuToggle} site={this.props.site} />
           </li>
            {!isMobile && (
                <li className="bmw-logo">
                  <Link to="/" aria-label="Home">
                    <LazyLoadImage
                        effect="blur"
                        src={bmwPerformanceLogoNew}
                        alt="BMW Performance Driving School"
                        className="bmw-logo"
                        title="bmw-logo"
                    />
                  </Link>
                </li>
            )}
          </ul>
        </nav>
        { showGiftCardBar ?
        <Link to="/giftcards" aria-label="GiftCards" id="gift-card-link">
          <div className="gift-card-bar">
            <img src={mStripes} title="mstripes"/>
            Give the gift of speed - Gift cards available
          </div> 
        </Link> 
        : 
        ""
        }
      </header>
    );
  }
}

Nav.defaultProps = {
  site: "BMW",
};

Nav.propTypes = {
  site: PropTypes.string,
};


export default withWindowSize(Nav);
